import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";
import {
  LoginResponse,
  Login,
  NFT,
  Sale,
  User,
  BalanceResponse,
} from "utils/types";
import { useNavigate } from "react-router-dom";
// import { queryClient } from 'index';

export const useLogin = () =>
  useMutation<LoginResponse, Error, Login>((data) =>
    client("auth/login", {
      data,
    })
  );

export const useGetAllNFTs = (name: string) =>
  useQuery<NFT[], Error>(["getAllNFTs", name], () =>
    client(`nft/getNFTsForSaleByName?name=${name}&limit=100&offset=0`)
  );

export const useGetNFT = (token?: string) =>
  useQuery<NFT, Error>(
    ["getNFT", token],
    () => client(`nft/getNftDetails/${token}`),
    {
      enabled: !!token,
    }
  );

export const useBuyNFT = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, string>(
    (token) => client(`nft/buyNft/${token}`),
    {
      onSuccess() {
        Promise.all([
          queryClient.invalidateQueries(["getUserNFTs"]),
          queryClient.invalidateQueries(["getAllNFTs"]),
        ]);
        navigate("/profile");
      },
    }
  );
};

export const useGetUserNFTs = () =>
  useQuery<NFT[], Error>(["getUserNFTs"], () => client(`nft/getUserNFT`));

export const useNFTForResale = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, Sale & { token?: string }>(
    (data) =>
      client(`nft/listNftForResale/${data?.token}`, {
        data,
      }),
    {
      onSuccess() {
        Promise.all([
          queryClient.invalidateQueries(["getUserNFTs"]),
          queryClient.invalidateQueries(["getAllNFTs"]),
        ]);
      },
    }
  );
};

export const useNFTChangePrice = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, Sale & { token?: string }>(
    (data) =>
      client(`nft/changePrice/${data?.token}`, {
        data,
      }),
    {
      onSuccess() {
        Promise.all([
          queryClient.invalidateQueries(["getUserNFTs"]),
          queryClient.invalidateQueries(["getAllNFTs"]),
        ]);
      },
    }
  );
};

export const useUnListNFT = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, string>(
    (id) => client(`nft/unListNft/${id}`),
    {
      onSuccess() {
        Promise.all([
          queryClient.invalidateQueries(["getUserNFTs"]),
          queryClient.invalidateQueries(["getAllNFTs"]),
        ]);
      },
    }
  );
};

export const useSetProfile = (user?: User) =>
  useQuery(["userData"], () => user, {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: !!user,
  });

export const useGetBalance = () =>
  useQuery<BalanceResponse, Error>(["userBalance"], () =>
    client("user/getBalance")
  );
