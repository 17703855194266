import { useQueryClient } from '@tanstack/react-query'

export function useGetCacheData<Type>(arg: string[]): Type | undefined {
  const queryClient = useQueryClient()
  return queryClient.getQueryData(arg, { exact: false })
}


export function useIsLogin() {
  return localStorage.getItem("token")
}