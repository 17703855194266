import React, { useState } from "react";
import placeholder from "assets/images/placeholder.png";
import polygonIcon from "assets/images/polygon.png";
import suiteIcon from "assets/images/suite.png";
import "./profile.scss";
import NftCard from "components/nftCard";
import { NFT, User } from "utils/types";
import { useGetCacheData, useIsLogin } from "utils/hooks";
import { useGetBalance, useGetUserNFTs } from "utils/apis/app.api";
import { Navigate, useNavigate } from "react-router-dom";
import BuyNft from "components/buyNft";
import Header from "components/header";
import { useQueryClient } from "@tanstack/react-query";
import Loader from "components/loader";
import { Alert } from "components/alert";

export default function Profile() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isSell, setIsCell] = useState(false);
  const [nftDetail, setNFTDetail] = useState<NFT>();
  const user = useGetCacheData<User>(["userData"]);
  const { data: nftList, isLoading } = useGetUserNFTs();
  const handleSell = (nft: NFT) => {
    setNFTDetail(nft);
    setIsCell(true);
  };
  const { data: useBalance } = useGetBalance()
  if (!useIsLogin()) return <Navigate to="/" />;

  const handleLogout = () => {
    queryClient.removeQueries(["userData"]);
    queryClient.clear();
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(user?.publicKey || "")
    if(user?.publicKey) Alert("success", "Address copied!")
  }
  return (
    <>
      <Header />
      <main className="main">
        <div className="container">
          <div className="profile">
            <div className="profile-img">
              <img src={placeholder} alt="profile img" />
            </div>
            <p className="address" onClick={handleCopy}>
              <svg
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.60938 2.02273V2.02273C2.60938 1.45789 3.06727 1 3.6321 1H5.8054C6.37023 1 6.82812 1.45789 6.82812 2.02273V2.02273M2.60938 2.02273V2.02273C2.60938 2.58756 3.06727 3.04545 3.6321 3.04545H5.8054C6.37023 3.04545 6.82812 2.58756 6.82812 2.02273V2.02273M2.60938 2.02273H2.5C1.39543 2.02273 0.5 2.91816 0.5 4.02273V10.25C0.5 11.3546 1.39543 12.25 2.5 12.25H6.9375C8.04207 12.25 8.9375 11.3546 8.9375 10.25V4.02273C8.9375 2.91816 8.04207 2.02273 6.9375 2.02273H6.82812"
                  stroke="#2B2D33"
                />
              </svg>
              {user?.publicKey.slice(0, 4)}...{user?.publicKey.slice(-3)}
            </p>
            <div className="profile-info">
              <strong>{user?.fullname}</strong>
              <br />
              <span>{user?.email}</span>
              <button onClick={handleLogout} className="primary-btn log-btn">
                Log out
              </button>
            </div>
            <div className="user-balances">
              <div className="balance shadow">
                <div className="label">
                  <img src={polygonIcon} alt="polygon icon" />
                  <span>MATIC</span>
                </div>
                <span className="value">{Number(useBalance?.maticBalance?.balanceInEth)?.toFixed(4)}</span>
              </div>
              <div className="balance shadow">
                <div className="label">
                  <img src={suiteIcon} alt="suite icon" />
                  <span>SUITE</span>
                </div>
                <span className="value">{Number(useBalance?.suiteBalance?.balanceInEth)?.toFixed(4)}</span>
              </div>
            </div>
            <h2>My NFT's</h2>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <div className="nft-list">
                  {nftList?.map((nft) => (
                    <NftCard nft={nft} onClick={() => handleSell(nft)} />
                  ))}
                </div>
                {nftDetail && (
                  <BuyNft
                    isOpen={isSell}
                    nft={nftDetail}
                    onClose={() => setIsCell(false)}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
}
