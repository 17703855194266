import "./processModal.scss";

interface Props {
  isOpen: boolean;
  children: JSX.Element;
  title?: string | JSX.Element;
  onClose?: () => void;
}

export default function Modal({ title, isOpen, children, onClose }: Props) {
  if (isOpen)
    return (
      <div
        className={`modal-container ${isOpen && "isOpen"}`}
        id="modal-opened"
      >
        <div className="modal shadow">
          <div className="modal__details">
            <button className="close-btn" onClick={() => onClose && onClose()}>
              X
            </button>
            <div className="modal__title">{title}</div>
            <div className="modal__description">{children}</div>
          </div>
        </div>
      </div>
    );
  else return null;
}
