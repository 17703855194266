import React, { useState } from "react";
import "./nft.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useGetNFT } from "utils/apis/app.api";
import coin from "assets/images/token.png";
import feature from "assets/images/feature.png";
import BuyNft from "components/buyNft";
import Header from "components/header";
import { User } from "utils/types";
import { useGetCacheData } from "utils/hooks";
import Loader from "components/loader";

export default function NftDetail() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isBuy, setIsBuy] = useState(false);
  const { data: singleNft, isLoading } = useGetNFT(token);
  const user = useGetCacheData<User>(["userData"]);
  const isOwner = singleNft?.owner === user?.publicKey;

  return (
    <>
      <Header />
      <main className="main">
        <section className="buy-nft">
          <div className="container">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <button className="primary-btn" onClick={() => navigate("/")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="15"
                    viewBox="0 0 11 15"
                    className="svg"
                  >
                    <path
                      d="M.139 7.24a.31.31 0 0 0 0 .52l10.328 7.179c.222.153.533 0 .533-.262v-1.892c0-.411-.206-.802-.554-1.044L4.34 7.502l6.105-4.243A1.27 1.27 0 0 0 11 2.215V.323c0-.262-.31-.415-.533-.262L.139 7.24z"
                      fill="#3A3831"
                      fill-rule="nonzero"
                    />
                  </svg>
                  Go Back
                </button>
                <div className="buy-now">
                  <div className="nft-img">
                    <img
                      src={singleNft?.image}
                      onError={(event) => {
                        (event.target as HTMLImageElement).src = feature;
                      }}
                      alt="nft"
                    />
                  </div>
                  <div className="nft-detail">
                    <div className="nft-token">
                      <span className="tag">#</span>
                      {singleNft?.tokenId}
                    </div>
                    <h2>Description</h2>
                    <p className="disc">{singleNft?.description}</p>
                    <button
                      className="primary-btn"
                      onClick={() => setIsBuy(!isBuy)}
                    >
                      {isOwner
                        ? singleNft?.isForResale
                          ? "update"
                          : "sell"
                        : "Buy now"}
                    </button>
                    <div className="nft-properties shadow">
                      <div className="row">
                        <span className="label">Rarity</span>
                        <span className="value">{singleNft?.name}</span>
                      </div>
                      <div className="row">
                        <span className="label">Rewards (24hrs)</span>
                        <span className="value">
                          {singleNft?.reward} <img src={coin} alt="coin" />
                        </span>
                      </div>
                      <div className="row">
                        <span className="label">Price</span>
                        <span className="value">
                          {singleNft?.price} <img src={coin} alt="coin" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        {singleNft && (
          <BuyNft
            isOpen={isBuy}
            nft={singleNft}
            onClose={() => setIsBuy(!isBuy)}
          />
        )}
      </main>
    </>
  );
}
