import React, { useState, useEffect } from "react";
import Modal from "components/modal";
import coin from "assets/images/token.png";
import { NFT, Sale, User } from "utils/types";
import { useGetCacheData, useIsLogin } from "utils/hooks";
import { useBuyNFT, useNFTChangePrice, useNFTForResale, useUnListNFT } from "utils/apis/app.api";
import { useForm } from "react-hook-form";
import "./buy-sell-nft.scss"
import { Alert } from "components/alert";
import LoginModal from "components/LoginModal";


export default function BuyNft({
  isOpen,
  onClose,
  nft,
}: {
  isOpen: boolean;
  onClose: () => void;
  nft: NFT
}) {
  const user = useGetCacheData<User>(["userData"])  
  const [isLogin, setLogin] = useState(false);
  const isLoginUser = useIsLogin();
  const { mutate: buyNFT, isLoading, isSuccess, reset: reset1 } = useBuyNFT();
  const [type, setType] = useState<"sell" | 'cancel' | "update">("sell");
  const { mutate: saleNFT, isLoading: isResealing, isSuccess: isResale, reset: reset2 } = useNFTForResale();
  const { mutate: updateNFT, isLoading: isUpdating, isSuccess: isUpdate, reset: reset3 } = useNFTChangePrice();
  const { mutate: cancelOrder, isLoading: isCanceling, isSuccess: isCancel, reset: reset4 } = useUnListNFT();

  const { handleSubmit, register } = useForm<Sale>();
  const isOwner = nft?.owner === user?.publicKey
  const onSubmit = (data: Sale) => {
    if(type === "sell") saleNFT({ ...data, token: nft?.tokenId })
    else if(type === "update") updateNFT({ ...data, token: nft?.tokenId })
    else if(type === "cancel" && nft?.tokenId) cancelOrder(nft?.tokenId)
  }

  useEffect(() => {
    if((isSuccess || isResale || isUpdate || isCancel)) {
      Alert("success", isSuccess ? `Successfully buy ${nft?.name}` : isResale ? `${nft?.name} listed successfully!` : isCancel ? `${nft?.name} Unlisted successfully!` : `Price updated!`)
        onClose()
        reset1()
        reset2()
        reset3()
        reset4()
    }
  } ,[isSuccess, onClose, isResale, isUpdate, isCancel, reset1, reset2, reset3, reset4, nft?.name])

  return (
    <>
    <Modal title={isOwner ? nft?.isForResale ? "Update" : "Sell" : "Buy"} isOpen={isOpen} onClose={onClose}>
        <>
      <div className="buy-detail">
        <img className="nft" src={nft?.image} alt="nft" />
        <div className="nft-token">
          <span className="tag">#</span>
          {nft?.tokenId}
        </div>
        <span className="nft-name">{nft?.name}</span>
        {!isOwner && <span className="nft-name">
          Price {nft?.price} <img src={coin} alt="coin" />
        </span>}
      </div>
      {isLoginUser && isOwner ? 
            <form className="form sell-detail" onSubmit={handleSubmit(onSubmit)}>
                <label className="label">Sell Price</label>
                <input
                    defaultValue={nft?.price}
                    {...register("price", { required: true })}
                    className="primary-input"
                    type="number"
                />
                {nft?.isForResale ? (
                    <>
                    <button disabled={isUpdating || isCanceling} onClick={() => setType("cancel")} className="primary-btn row-btn">
                        Revoke
                    </button>{" "}
                    <button disabled={isUpdating || isCanceling} onClick={() => setType("update")} className="primary-btn row-btn">
                        Update Order
                    </button>
                    </>
                ) : 
                (
                    <button disabled={isResealing} className="primary-btn">Place Order</button>
                )}
            </form>
         : 
          <button
            className="primary-btn modal-btn"
            disabled={isLoading}
            onClick={() => isLoginUser ? (nft?.tokenId && buyNFT(nft?.tokenId)) : setLogin(true)}
          >
            {isLoginUser ? "pay" : "Login to buy"}
          </button>}
          </>
    </Modal>
          <LoginModal isLogin={isLogin} onClose={() => setLogin(false)} />
</>
  );
}
