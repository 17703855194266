import React, { useState } from "react";
import NftCard from "components/nftCard";
import { CHIPS } from "utils/const";
import { useGetAllNFTs } from "utils/apis/app.api";
import "./landing.scss";
import { NFT } from "utils/types";
import BuyNft from "components/buyNft";
import Header from "components/header";
import Loader from "components/loader";

export default function Landing() {
  const [activeContent, setActiveContent] = useState<string>("All");
  const [isSell, setIsCell] = useState(false);
  const [nftDetail, setNFTDetail] = useState<NFT>();

  const handleBuySell = (nft: NFT) => {
    setNFTDetail(nft);
    setIsCell(true);
  };

  const { data: allNFTs, isLoading } = useGetAllNFTs(activeContent);

  return (
    <>
      <Header />
      <main className="main">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="container">
              <div className="chips">
                {CHIPS?.map((chip) => (
                  <button
                    onClick={() => setActiveContent(chip)}
                    className={`chip ${activeContent === chip && "active"}`}
                  >
                    {chip}
                  </button>
                ))}
              </div>
            </div>
            <section className="listed-nft">
              <div className="container">
                <div className="nft-list">
                  {allNFTs?.map((nft) => (
                    <NftCard
                      key={nft?._id}
                      nft={nft}
                      onClick={() => handleBuySell(nft)}
                    />
                  ))}
                </div>
              </div>
            </section>
            {nftDetail && (
            <BuyNft
              isOpen={isSell}
              nft={nftDetail}
              onClose={() => setIsCell(false)}
            />
            )}
          </>
        )}
      </main>
    </>
  );
}
