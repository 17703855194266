import React from "react";
import "./nft-card.scss";
import coin from "assets/images/token.png";
import { NFT, User } from "utils/types";
import { useGetCacheData } from "utils/hooks";
import { Link } from "react-router-dom";
import feature from "assets/images/feature.png";

interface Props {
  nft?: NFT;
  onClick: () => void;
}

export default function NftCard({ nft, onClick }: Props) {
  const user = useGetCacheData<User>(["userData"]);
  const isOwner = nft?.owner === user?.publicKey;
  return (
    <div className="nft-card shadow">
      <Link to={`/nft/${nft?.tokenId}`}>
        <span className="nft-name">{nft?.name}</span>
        <img
          className="nft-img"
          src={nft?.image}
          onError={(event) => {
            (event.target as HTMLImageElement).src = feature;
          }}
          alt="nft"
        />
        <div className="nft-token">
          <span className="tag">#</span>
          {nft?.tokenId}
        </div>
      </Link>
      <div className="nft-util">
        <span className="nft-price">
          {nft?.price} <img src={coin} alt="coin" />
        </span>
        <button className="secondary-btn" onClick={onClick}>
          {isOwner ? (nft?.isForResale ? "update" : "sell") : "buy"}
        </button>
      </div>
    </div>
  );
}
