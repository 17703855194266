import React, { useEffect } from "react";
import Modal from "components/modal";
import Logo from "assets/images/logo.png";
import "./login.scss";
import { useForm } from "react-hook-form";
import { Login } from "utils/types";
import { useNavigate } from "react-router-dom";
import { useLogin, useSetProfile } from "utils/apis/app.api";

export default function LoginModal({
  isLogin,
  onClose,
}: {
  isLogin: boolean;
  onClose: () => void
}) {
  const { handleSubmit, register } = useForm<Login>();
  const navigate = useNavigate();
  const { mutate: userLogin, isLoading,  isSuccess, data } = useLogin();
  const onSubmit = (data: Login) => userLogin(data);

  useEffect(() => {
    if (isSuccess && isLogin) {
      onClose();
      localStorage.setItem("token", data?.token?.access_token);
      navigate("/landing")
    }
  }, [isLogin, isSuccess, data?.token?.access_token, data?.user, onClose, navigate]);
  
  useSetProfile(data?.user)

  return (
    <Modal
      isOpen={isLogin}
      onClose={onClose}
      title={
        <div className="login-header">
          <img width="60px" src={Logo} alt="" />
          <span className="sub-text">Welcome To Metasuite City</span>
        </div>
      }
    >
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("email", { required: "Email required" })}
          className="primary-input"
          type="email"
          placeholder="Email address"
        />
        <input
          {...register("password", { required: "Password required" })}
          className="primary-input"
          type="password"
          placeholder="Password"
        />
        <button disabled={isLoading} className="primary-btn">
          Login
        </button>
        <p className="helper-text">
          Please{" "}
          <a
            className="orange-label"
            href="https://nftsuit.io"
            rel="noreferrer"
            target="_blank"
          >
            download
          </a>{" "}
          Metasuite City Mobile App to register.
        </p>
      </form>
    </Modal>
  );
}
