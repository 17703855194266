import { QueryCache } from '@tanstack/react-query'
import { Alert } from 'components/alert';
import { API_URL } from 'utils/const';

const queryCache = new QueryCache({
  onError: error => {
    console.log(error)
  },
  onSuccess: data => {
    console.log(data)
  },
})

async function client(
  endpoint: string,
  {
    data,
    headers: customHeaders,
    ...customConfig
  }: Partial<RequestInit> & { token?: string; data?: unknown } = {},
) {

  const token = localStorage.getItem('token')

  const config: RequestInit = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `${token}` : '',
      'Content-Type': data ? 'application/json' : '',
      ...customHeaders,
    },
    ...customConfig,
  }

  return window.fetch(`${API_URL}/${endpoint}`, config).then(async response => {
    const data = await response.json()
    if (response.status === 401) {
      queryCache.clear()
      Alert("error", data?.message)
      return Promise.reject(data)
    }
    if (response.ok) {
      return data
    } else {
      Alert("error", data?.message)
      return Promise.reject(data)
    }
  })
}

export { client }
