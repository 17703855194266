export const API_URL = "https://api.metasuite.city";

export const CHIPS = [
  "All",
  "Common",
  "Uncommon",
  "Rare",
  "Epic",
  "Legendary",
  "Mythic",
  "Full Island",
];
